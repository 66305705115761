<template>
  <div
    id="class"
    :style="`background-color: ${appThemeConfig.appBgColor}; margin-top: ${
      isApp ? systemBarHeight : 0
    }px`"
  >
    <class-table-app-header ref="detailAppHead"> </class-table-app-header>
    <div
      id="class-table-scroll"
      class="class-table-scroll show-scroll"
      :style="`height:${
        isApp ? screenHeight - systemBarHeight - 56 : screenHeight - 56
      }px;`"
    >
      <van-pull-refresh
        style="min-height: 100%"
        v-model="isRefreshing"
        @refresh="onRefresh"
      >
        <div class="class-content">
          <div class="footer-takeplace"></div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate, setSystemColor } from "@/mixin/mixin";
import { isEmpty } from "@/utils/common";
// eslint-disable-next-line no-unused-vars
import $api from "@/api/api";
import ClassTableAppHeader from "@/views/classTable/components/classTableAppHeader";

export default {
  name: "classTableIndex",
  mixins: [setSystemColor, buttonVibrate],
  components: {
    ClassTableAppHeader,
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appTheme: "appTheme",
      appThemeConfig: "appThemeConfig",
      systemBarHeight: "systemBarHeight",
      classSearchHistory: "classSearchHistory",
      screenHeight: "screenHeight",
    }),
  },
  data: () => ({
    isLoading: true,
    isRefreshing: false,
    firstCreat: true,
  }),
  methods: {
    isEmpty,
    onRefresh() {
      // 调接口
      this.isRefreshing = false;
    },

    init() {
      this.$store.dispatch("setShowSysAppTab", false);
      this.$store.dispatch(
        "setAppSystemBarColor",
        this.appThemeConfig.systemBarColor
      );
      this.setSystemFootColor(this.appThemeConfig.footerBarColer);
      // 调接口
    },
  },
  created() {
    console.info("classtable组件被创建");
    this.init();
  },
  mounted() {
    console.info("classtable组件被挂载");
  },
  activated() {
    console.info("classtable组件被激活");
    if (this.firstCreat) {
      this.firstCreat = false;
    } else {
      this.init();
    }
  },
  deactivated() {
    console.info("classtable组件被暂停");
  },
};
</script>

<style lang="scss" scoped>
#class {
  height: 100%;
  overflow: hidden;
  .class-table-scroll {
    overflow-y: auto;

    .class-content {
      width: 100%;
      max-width: 100%;
      min-height: 90vh;
      padding: 0 0.5rem;
      class-wrap: break-class;

      .footer-takeplace {
        width: 100%;
        height: 7.5rem;
      }
    }
  }
}
</style>
