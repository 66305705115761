<template>
  <v-app-bar
    :color="appThemeConfig.headColor"
    :dark="appThemeConfig.dark"
    elevate-on-scroll
    scroll-target="#class-table-scroll"
    class="app-bar rounded-b-lg"
  >
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        :src="require('@/assets/background/colorful/bg-star.jpg')"
        gradient="to bottom, rgba(250,250,250,.6), rgba(255,255,255,0.7), rgba(255,255,255,0.9)"
      >
      </v-img>
    </template>

    <v-btn @touchstart="zzzMid" icon @click="goBack">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-app-bar-title style="font-size: 16px; color: #7a7a7a"
      >今日学习课程</v-app-bar-title
    >

    <v-spacer></v-spacer>

    <v-progress-linear
      :active="isLoading"
      :indeterminate="true"
      height="2"
      absolute
      top
      color="deep-purple accent-1"
    ></v-progress-linear>

    <v-chip small color="indigo lighten-2" text-color="white">
      {{ totalClasses }} 节
    </v-chip>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate } from "@/mixin/mixin";

export default {
  name: "classTableAppHeader",
  mixins: [buttonVibrate],
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      systemBarHeight: "systemBarHeight",
      appThemeConfig: "appThemeConfig",
    }),
  },
  data: () => ({
    totalClasses: 0,
  }),
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar {
  z-index: 3;
}
</style>
